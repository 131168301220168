import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "col-xxl-12" }
const _hoisted_3 = { class: "card card-xl-stretch mb-xl-8 p-10 p-lg-15 mx-auto d-flex justify-content-center flex-column text-center" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", {
          innerHTML: _ctx.translate('EMAIL_CONFIRMATION'),
          class: "text-uppercase text-primary"
        }, null, 8, _hoisted_4),
        _createElementVNode("div", {
          innerHTML: _ctx.translate('EMAIL_VERIFICAION_DESCRIPTION'),
          class: "w-50 mx-auto text-center lead"
        }, null, 8, _hoisted_5),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.verify && _ctx.verify(...args))),
          class: "btn btn-dark-blue mx-auto w-50 mt-5"
        }, _toDisplayString(_ctx.translate("VERIFY_EMAIL_ADDRESS")), 1),
        (_ctx.isReset)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resend && _ctx.resend(...args))),
              class: "btn btn-dark-blue mx-auto w-50 mt-5"
            }, _toDisplayString(_ctx.translate("RESEND_EMAIL_VERIFICATION")), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}