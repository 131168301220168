
import { defineComponent, ref } from "vue";
import { translate } from "@/hooks/TranslateService";
import { useRoute, useRouter } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default defineComponent({
  name: "EmailVerification",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isReset = ref<boolean>(false);
    const successAccountActivation = ref<string>(translate("FEEDBACK_ACCOUNT_ACTIVATION_SUCCESSFUL")+" "+translate("FEEDBACK_ADMIN_APPROVAL_MAIL_SENDING_SUCCESSFUL"));

    const verify = () => {
      store
        .dispatch(Actions.EMAIL_VERIFY, {
          user_id: route.params.user_id,
          user_hash: route.params.user_hash,
        })
        .then((res) => {
          if (res.success) {
            Swal.fire({
              html: successAccountActivation.value,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              // Go to page after successfully email confirmed
              router.push({ name: "sign-in" });
            });
          } else {
            if (res.verified) {
              Swal.fire({
                text: "The email was already verified",
                icon: "warning",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              });
            } else {
              isReset.value = true;
              Swal.fire({
                text: "Invalid email verification link!",
                icon: "error",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              });
            }
          }
        });
    };

    const resend = () => {
      store
        .dispatch(Actions.EMAIL_RESEND, {
          user_id: route.params.user_id,
        })
        .then(() => {
          Swal.fire({
            title: "Sucess",
            text: "The Email has sent successfully!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
        });
    };

    return {
      translate,
      verify,
      resend,
      isReset,
    };
  },
});
